export const userTableColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "fullname",
    label: "Full Name",
    minWidth: 70,
    align: "left",
  },
  {
    id: "doctors",
    label: "Doctors",
    minWidth: 100,
    align: "left",
  },
  {
    id: "gender",
    label: "Gender",
    minWidth: 70,
    align: "left",
  },
  {
    id: "dob",
    label: "Date of Birth",
    minWidth: 120,
    align: "left",
  },
  {
    id: "city",
    label: "City",
    minWidth: 70,
    align: "left",
  },
  {
    id: "languagePreference",
    label: "Language",
    minWidth: 70,
    align: "left",
  },
  {
    id: "phone",
    label: "Phone No.",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "email",
  //   label: "Email",
  //   minWidth: 70,
  //   align: "left",
  // },
  {
    id: "createdAt",
    label: "Registration Date",
    minWidth: 70,
    align: "left",
  },
  {
    id: "details",
    label: "Attachment",
    minWidth: 70,
    align: "center",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];
export const doctorTableColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "fullname",
    label: "Full Name",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "centername",
  //   label: "Center Name",
  //   minWidth: 70,
  //   align: "left",
  // },
  // {
  //   id: "gender",
  //   label: "Gender",
  //   minWidth: 70,
  //   align: "left",
  // },

  {
    id: "speciality",
    label: "Speciality",
    minWidth: 70,
    align: "left",
  },

  {
    id: "hospitalName",
    label: "Centre Name",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "dob",
  //   label: "Date of Birth",
  //   minWidth: 120,
  //   type: "date",
  //   align: "left",
  // },
  {
    id: "city",
    label: "City",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "languagePreference",
  //   label: "Language",
  //   minWidth: 70,
  //   align: "left",
  // },
  {
    id: "phone",
    label: "Phone No.",
    minWidth: 70,
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 70,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Registration Date",
    minWidth: 70,
    align: "left",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

export const userFormFields = [
  { name: "fullname", label: "Full Name", type: "text", required: true },
  // {
  //   name: "centername",
  //   label: "Center Name",
  //   type: "text",
  //   required: false,
  // },
  {
    name: "gender",
    label: "Gender",
    type: "option",
    options: ["Male", "Female", "Other"],
    required: true,
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    required: false,
  },
  // {
  //   name: "state",
  //   label: "State",
  //   isMultiSelect: true,
  //   options: [{ label: "Haryana", value: "Haryana" },
  //   { label: "Jharkhand", value: "Jharkhand" }],
  //   required: false,
  // },

  {
    name: "state",
    label: "State",
    type: 'text',
    required: false,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    required: false,
  },

  {
    name: "languagePreference",
    label: "Language",
    type: "option",
    options: ["ENGLISH", "HINDI"],
    required: true,
  },
  {
    name: "phone",
    label: "Phone No.",
    type: "text",
    required: false,
  },
  // {
  //   name: "email",
  //   label: "Email",
  //   type: "text",
  //   required: false,
  // },
  // {
  //   name: "createdAt",
  //   label: "Registration Date",
  //   type: "datetime-local",
  //   required: false,
  // },
];

export const DoctorFormFields = [
  { name: "fullname", label: "Full Name (In English)", type: "text", required: true },
  // {
  //   name: "centername",
  //   label: "Center Name",
  //   type: "text",
  //   required: false,
  // },
  // {
  //   name: "gender",
  //   label: "Gender",
  //   type: "option",
  //   options: ["MALE", "FEMALE"],
  //   required: true,
  // },
  // {
  //   name: "dob",
  //   label: "Date of Birth",
  //   type: "date",
  //   required: false,
  // },
  { name: "hindiFullname", label: "Full Name (In Hindi)", type: "text", required: true },
  { name: "speciality", label: "Speciality (In English)", type: "text", required: true },
  { name: "hindiSpeciality", label: "Speciality(In Hindi)", type: "text", required: true },
  {
    name: "hospitalName",
    label: "Centre Name (In English)",
    type: "text",
    required: true,
  },
  {
    name: "hindiHospitalName",
    label: "Centre Name (In Hindi)",
    type: "text",
    required: true,
  },
  {
    name: "state",
    label: "State(In English)",
    type: 'text',
    required: false,
  },
  {
    name: "hindiState",
    label: "State(In Hindi)",
    type: 'text',
    required: false,
  },
  {
    name: "city",
    label: "City(In English)",
    type: "text",
    required: false,
  },
  {
    name: "hindiCity",
    label: "City(In Hindi)",
    type: "text",
    required: false,
  },
  // {
  //   name: "languagePreference",
  //   label: "Language",
  //   type: "option",
  //   options: ["ENGLISH", "HINDI"],
  //   required: true,
  // },
  // {
  //   name: "languagePreference",
  //   label: "Language",
  //   type: "text",
  //   required: true,
  // },
  {
    name: "phone",
    label: "Phone No.",
    type: "text",
    required: false,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    required: false,
  },
  // {
  //   name: "password",
  //   label: "Password",
  //   type: "text",
  //   required: false,
  // },
  // {
  //   name: "createdAt",
  //   label: "Registration Date",
  //   type: "datetime-local",
  //   required: false,
  // },
];
