import axios from "axios";
import { toastMessage } from "../utils/toastMessage";

const API_BASE_URL = "https://gut-buddy-api.applore.in";
//const API_BASE_URL = "https://api.gut-buddy.com";
// const API_BASE_URL = "http://localhost:9000";
//const API_BASE_URL = "http://localhost:10007";

let token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleRequestError = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      toastMessage("Your session is expired. Please sign in again", "error");
      localStorage.removeItem("token");
      window.location.replace("/login");
    }
    let err = {
      errorType: "HTTP Error",
      status: error.response.status,
      message: error.response.data,
      response: "Failure",
    };
    return err;
  } else if (error.request) {
    let err = {
      errorType: "Network Error",
      message: error.message,
      response: "Failure",
    };
    return err;
  } else {
    // Handle other errors
    console.error("Error:", error.message);
    let err = {
      errorType: "Other Error",
      message: error.message,
      response: "Failure",
    };
    return err; // Ensure we return the error object
  }
};

// GET request function
export const get = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};

// POST request function
export const post = async (url, data = {}) => {
  try {
    const response = await axiosInstance.post(url, data);
    return { data: response.data, response: "Success" };
  } catch (error) {
    return handleRequestError(error);
  }
};

// PUT request function
export const put = async (url, data = {}) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error) {
    return handleRequestError(error);
  }
};

// PATCH request function
export const patch = async (url, data = {}) => {
  try {
    const response = await axiosInstance.patch(url, data);
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};

// DELETE request function
export const del = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};

// POST with files
export const postFiles = async (url, data, method) => {
  method = method ? method : "POST";
  try {
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return { data: responseData, response: "Success" };
  } catch (error) {
    return handleRequestError(error);
  }
};
