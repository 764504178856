/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, put, post, postFiles, patch } from "../../config/axios";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { deleteAPI } from "../../helper/apiCallHelper";
import { useDebouncedValue } from "../../helper/debounce";
import { toastMessage } from "../../utils/toastMessage";
import FormModal from "../../components/Custom/FormModal/FormModal";
import {
  gutCategoryTableColumns,
  gutCategoryFormFields,
} from "../../constants/gutCategoriesPage";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const debouncedSearch = useDebouncedValue(search, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  const fetchUsers = async (searchValue) => {
    setLoading(true);
    try {
      const response = await get(
        `api/dashboard/apputility/getCategory?page=${page}&limit=${10}&search=${searchValue}&type=GUT_VIDEOS`
      );
      setUsers(
        response?.data.map((item) => ({
          ...item,
          action: { edit: true, delete: true },
        }))
      );
      setLoading(false);
      setPageCount(response?.totalPage);
    } catch (error) {
      console.log("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search === "") {
      fetchUsers("");
    } else if (debouncedSearch) {
      fetchUsers(debouncedSearch);
    }
  }, [search, debouncedSearch, message, page]);

  const handleEdit = (row) => {
    // Implement the edit action for the selected row
    openModal("edit", row);
  };

  const handleDelete = (row) => {
    openModal("delete", row);
  };

  const handleDeleteUser = async (row) => {
    try {
      const deletedCategory = await patch(
        `/api/dashboard/apputility/deleteCategory/${row._id}`
      );
      setMessage(deletedCategory?.message);
      toastMessage(deletedCategory?.message, "success");
    } catch (error) {
      console.error(error);
      setMessage("Something went wrong!");
      toastMessage("Something went wrong!", "error");
    }
    setDeleteModalOpen(false);
  };

  const handleStatus = (row) => {
    // Implement the status chnage for the selected row
    console.log("Delete clicked for row34:", row);
  };

  const handleActive = async (id, active) => {
    setLoading(true);
    let response = await put(
      `/api/dashboard/apputility/updateCategory?id=${id}`,
      {
        active: active,
      }
    );

    const categoryTitle = response?.data?.title;
    const message = active
      ? `${categoryTitle} successfully activated.`
      : `${categoryTitle} successfully deactivated.`;

    setLoading(false);
    setMessage(message);
    toastMessage(message, "success");
  };

  const handleSearch = (searchText) => {
   setSearch(searchText.trim());
    setPage(1);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleChange = (page) => {
    setPage(page);
  };

  const openModal = (type, dataForEdit) => {
    if (type === "add") {
      setIsModalOpen(true);
    } else if (type === "edit") {
      setEditModal(true);
      setEditData(dataForEdit);
    } else if (type === "delete") {
      setDeleteModalOpen(true);
      setDeleteUser(dataForEdit);
    }
  };

  const closeModal = (type) => {
    if (type === "add") {
      setIsModalOpen(false);
      setEditData({});
    } else if (type === "edit") {
      setEditModal(false);
      setEditData({});
    }
  };

  const handleSubmit = async (formData, isEditing, id) => {
    setLoading(true);
    try {
      if (isEditing) {
        let form = new FormData();
        const { ...data } = formData;
        if (formData.asset instanceof File) {
          const validImageTypes = ["image/svg+xml", "image/png", "image/jpeg"];
          if (!validImageTypes.includes(formData.asset.type)) {
            setLoading(false);
            toastMessage("Category Icon (.svg, .png, .jpeg) is required", "error");
            return;
          }
          form.append("file", formData?.asset);
          const res = await postFiles("/api/app/user/uploadImage", form);
          data.asset = res.data.url;
        }
        let response = await put(
          `/api/dashboard/apputility/updateCategory?id=${id}`,
          data
        );
        setMessage(response.message);
        toastMessage(response.message, "success");
      } else {
        const validImageTypes = ["image/svg+xml", "image/png", "image/jpeg"];
        if (!validImageTypes.includes(formData.asset.type)) {
          setLoading(false);
          toastMessage("Category Icon (.svg, .png, .jpeg) is required", "error");
          return;
        }
        formData = {
          ...formData,
          type: "GUT_VIDEOS",
        };
        let form = new FormData();
        form.append("file", formData?.asset);
        const res = await postFiles("/api/app/user/uploadImage", form);
        const { ...data } = formData;
        data.asset = res.data.url;
        const ret = await post("/api/dashboard/appUtility/addCategory", data);
        setMessage("Successfully added");
        toastMessage(ret.data.message,"success");
        setIsModalOpen(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setMessage("Error while processing the request");
      toastMessage("Error while updating", "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "1rem" }}>
          <Typography variant="h5">Gut Categories</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <Searchbar
                search={handleSearch}
                placeholder={"Search by category name"}
                debounceTime={1000}
              />
            </div>

            <Button
              onClick={() => openModal("add")}
              variant="outlined"
              startIcon={<AddIcon fontSize="large" />}
              style={{ fontWeight: "bold" }}
            >
              add gut category
            </Button>
          </div>
          <CustomTable
            data={users}
            columns={gutCategoryTableColumns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleStatus={handleStatus}
            handleActive={(row, active) => handleActive(row, active)}
            handlePageChange={(page) => handleChange(page)}
            pageNumber={page}
            pageCount={pageCount}
            loading={loading}
          />
        </div>
      </Layout>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteUser}
        data={deleteUser}
      />
      <FormModal
        accept="image/*"
        isOpen={isModalOpen || editModal}
        onClose={() => closeModal(editModal ? "edit" : "add")}
        onSubmit={handleSubmit}
        fields={gutCategoryFormFields}
        header={editModal ? "Edit Gut Category" : "Add Gut Category"}
        initialData={editData}
        isEditing={editModal}
      />
    </>
  );
};
export default Users;
