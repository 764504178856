// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import { Autocomplete, MenuItem, Select } from "@mui/material";
// import style from "./PatientModal.module.css";

// import Indian_states_cities_list from "indian-states-cities-list";

// const getCurrentDate = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = String(today.getMonth() + 1).padStart(2, "0");
//     const day = String(today.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
// };

// const PatientModal = ({
//     accept,
//     setSelectedCategory,
//     isOpen,
//     onClose,
//     onSubmit,
//     fields,
//     resetFormData,
//     header,
//     initialData,
//     defaultData,
//     isEditing,
// }) => {

//     const [selectedState, setSelectedState] = useState(null);
//     const [cities, setCities] = useState([]);
//     const initialFormData = {};
//     const initialErrors = {};
//     fields.forEach((field) => {
//         initialFormData[field.name] = field.isMultiSelect ? [] : "";
//         initialErrors[field.name] = "";
//     });
//     const [formData, setFormData] = useState(initialFormData);
//     const [errors, setErrors] = useState(initialErrors);

//     // console.log(Indian_states_cities_list.STATE_WISE_CITIES.Haryana)

//     useEffect(() => {
//         if (initialData) {
//             const updatedFormData = { ...initialFormData };
//             Object.keys(initialData).forEach((key) => {
//                 updatedFormData[key] = initialData[key];
//             });
//             fields.forEach((field) => {
//                 if (field.type === "date" && initialData[field.name]) {
//                     updatedFormData[field.name] = new Date(initialData[field.name])
//                         .toISOString()
//                         .split("T")[0];
//                 }
//             });
//             setFormData(updatedFormData);
//         } else {
//             setFormData(initialFormData);
//         }
//     }, [initialData]);

//     const validateFormData = () => {
//         const newErrors = {};

//         fields.forEach((field) => {
//             // if(field.name=='category' || 'subCategory') {
//             //   return;
//             // }
//             // console.log(">>>>>>", formData[field.name], field.name)
//             const isEmpty = !formData[field.name];
//             const isEditingFileField = field.type === "file" && field.isEditing;
//             const isFileEmpty = isEditingFileField && !formData[field.name].name;

//             if (field.required && (isEmpty || isFileEmpty)) {
//                 newErrors[field.name] = `${field.label} is required`;
//             } else {
//                 newErrors[field.name] = "";
//             }
//             // Trim the field value if it's a string before validation
//             if (typeof formData[field.name] === 'string') {
//                 formData[field.name] = formData[field.name].trim();
//             }
//             if (
//                 field.name === "phone" &&
//                 formData[field.name] &&
//                 !/^\d{10}$/.test(formData[field.name])
//             ) {
//                 newErrors[field.name] = "Phone number must be exactly 10 digits.";
//             }
//             if (
//                 field.name === "email" &&
//                 formData[field.name] &&
//                 !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData[field.name])
//             ) {
//                 newErrors[field.name] = "Please enter a valid email address.";
//             }
//         });

//         setErrors(newErrors);
//         return Object.values(newErrors).every((error) => error === "");
//     };

//     // Use useEffect to initialize selectedState and cities based on defaultData or initialData
//   useEffect(() => {
//     if (defaultData?.state) {
//       setSelectedState(defaultData.state);
//       setCities(Indian_states_cities_list.STATE_WISE_CITIES[defaultData.state] || []);
//     } else if (initialData?.state) {
//       setSelectedState(initialData.state);
//       setCities(Indian_states_cities_list.STATE_WISE_CITIES[initialData.state] || []);
//     }
//   }, [defaultData, initialData]);
    
//     const handleChange = (fieldName, fieldType) => (event, newValue) => {
//         if (fieldName === "state") {
//             setSelectedState(newValue);
//             setCities(Indian_states_cities_list.STATE_WISE_CITIES[newValue] || []);
//             setFormData({ ...formData, [fieldName]: newValue, city: "" });
//         } else if (fieldName === "city") {
//             setFormData({ ...formData, [fieldName]: newValue ? newValue.value : "" });
//         } else {
//             if (Array.isArray(newValue)) {
//                 setFormData({ ...formData, [fieldName]: newValue });
//             } else if (fieldType === "file") {
//                 const file = event.target.files[0];
//                 setFormData({ ...formData, [fieldName]: file });
//             } else {
//                 setFormData({ ...formData, [fieldName]: event.target.value });
//             }
//         }
//     };


//     const handleSubmit = () => {
//         if (validateFormData()) {
//             onSubmit(formData, isEditing, initialData._id);
//             onClose();
//         }
//     };

//     const handleCancel = () => {
//         // Reset selectedState and cities states
//         setSelectedState(null);
//         setCities([]);
//         const clearedFormData = {};
//         fields.forEach((field) => {
//             clearedFormData[field.name] = field.isMultiSelect ? [] : "";
//         });
//         setFormData(clearedFormData);
//         setErrors(initialErrors);
//         onClose();
//     };

//     useEffect(() => {
//         if (resetFormData) {
//             setFormData(initialFormData);
//             setErrors(initialErrors);
//         }
//     }, [resetFormData]);

//     const getNestedProperty = (obj, path) => {
//         const keys = path.split(".");
//         let result = obj;

//         for (const key of keys) {
//             result = result[key];
//             if (result === undefined) {
//                 return "";
//             }
//         }
//         return result;
//     };
//     const getDropDownValue = (obj, path) => {
//         const keys = path.split(".");
//         let result = obj;

//         for (const key of keys) {
//             result = result[key];
//             if (result === undefined) {
//                 return "";
//             }
//         }
//         return result?._id;
//     };

//     const states = Object.keys(Indian_states_cities_list.STATE_WISE_CITIES);

//     return (
//         <Modal
//             open={isOpen}
//             onClose={handleCancel}
//             aria-labelledby="modal-title"
//             aria-describedby="modal-description"
//         >
//             <Box
//                 sx={{
//                     position: "absolute",
//                     top: "50%",
//                     left: "50%",
//                     transform: "translate(-50%, -50%)",
//                 }}
//                 className={style.main_div}
//             >
//                 <Typography
//                     id="modal-title"
//                     variant="h5"
//                     component="h2"
//                     align="center"
//                     style={{ textDecoration: "underline" }}
//                 >
//                     {header}
//                 </Typography>
//                 <form>
//                     <div style={{ margin: "1rem 0" }}>
//                         {fields.map((field) => {
//                             let fieldname = field.name;
//                             return (
//                                 <div key={field.name} style={{ marginBottom: "10px" }}>
//                                     <Typography>{field.label}</Typography>
//                                     {field.name === "state" ? (
//                                         <Autocomplete
//                                             options={states}
//                                             value={formData[field.name] || ""}
//                                             onChange={handleChange(field.name)}
//                                             renderInput={(params) => (
//                                                 <TextField
//                                                     {...params}
//                                                     error={!!errors[field.name]}
//                                                     helperText={errors[field.name]}
//                                                     fullWidth
//                                                 />
//                                             )}
//                                         />
//                                     ) : field.name === "city" ? (
//                                         <Autocomplete
//                                             options={cities}
//                                             value={formData.city || ""}
//                                             onChange={handleChange('city')}
//                                             renderInput={(params) => (
//                                                 <TextField
//                                                     {...params}
//                                                     error={!!errors['city']}
//                                                     helperText={errors['city']}
//                                                     fullWidth
//                                                 />
//                                             )}
//                                         />
//                                     ) : field.isMultiSelect ? (
//                                         <Autocomplete
//                                             disabled={field.disabled ? true : false}
//                                             disablePortal
//                                             id={field.name}
//                                             options={field.options}
//                                             value={formData[field.name]}
//                                             // onChange={(_, newValue) =>
//                                             //   handleChange(field.name)(_, newValue)
//                                             // }
//                                             // getOptionLabel={(option) => option.label}
//                                             // getOptionValue={(option) => option.value}
//                                             renderInput={(params) => (
//                                                 <TextField
//                                                     {...params}
//                                                     error={!!errors[field.name]}
//                                                     helperText={errors[field.name]}
//                                                 />
//                                             )}
//                                         />
//                                     ) : field.type === "option" ? (
//                                         <div>
//                                             <Select
//                                                 label={field.label}
//                                                 id={field.name}
//                                                 name={field.name}
//                                                 value={
//                                                     getDropDownValue(formData, field.name) ??
//                                                     formData[field.name]
//                                                 }
//                                                 onChange={(event) => handleChange(field.name)(event)}
//                                                 fullWidth
//                                                 error={!!errors[field.name]}
//                                                 helperText={errors[field.name]}
//                                                 disabled={field.disabled ? true : false}
//                                             >
//                                                 {field.options.map((option) => (
//                                                     <MenuItem key={option} value={option}>
//                                                         {option}
//                                                     </MenuItem>
//                                                 ))}
//                                             </Select>
//                                             {errors[field.name] && (
//                                                 <Typography
//                                                     variant="body2"
//                                                     color="error"
//                                                     sx={{
//                                                         margin: "0.75rem",
//                                                         marginTop: "0.4rem",
//                                                         fontSize: "0.75rem",
//                                                     }}
//                                                 >
//                                                     {errors[field.name]}
//                                                 </Typography>
//                                             )}
//                                         </div>
//                                     ) : field.type === "category" ? (
//                                         <div>
//                                             <Select
//                                                 label={field.label}
//                                                 id={field.name}
//                                                 name={field.name}
//                                                 defaultValue={formData.category?._id}
//                                                 value={
//                                                     getDropDownValue(formData, field.name) ??
//                                                     formData[field.name]
//                                                 }
//                                                 onChange={(event) => {
//                                                     handleChange(field.name)(event);
//                                                     setSelectedCategory(event.target.value);
//                                                 }}
//                                                 fullWidth
//                                                 error={!!errors[field.name]}
//                                                 helperText={errors[field.name]}
//                                                 disabled={field.disabled ? true : false}
//                                             >
//                                                 {field.options.map((option) => (
//                                                     <MenuItem key={option._id} value={option._id}>
//                                                         {option.title}
//                                                     </MenuItem>
//                                                 ))}
//                                             </Select>
//                                             {errors[field.name] && (
//                                                 <Typography
//                                                     variant="body2"
//                                                     color="error"
//                                                     sx={{
//                                                         margin: "0.75rem",
//                                                         marginTop: "0.4rem",
//                                                         fontSize: "0.75rem",
//                                                     }}
//                                                 >
//                                                     {errors[field.name]}
//                                                 </Typography>
//                                             )}
//                                         </div>
//                                     ) : field.type === "subCategory" ? (
//                                         <div>
//                                             <Select
//                                                 label={field.label}
//                                                 id={field.name}
//                                                 name={field.name}
//                                                 // defaultValues={getDropDownValue(formData, fieldname)}
//                                                 value={
//                                                     getDropDownValue(formData, field.name) ??
//                                                     formData[field.name]
//                                                 }
//                                                 onChange={(event) => {
//                                                     handleChange(field.name)(event);
//                                                 }}
//                                                 fullWidth
//                                                 error={!!errors[field.name]}
//                                                 helperText={errors[field.name]}
//                                                 disabled={field.disabled ? true : false}
//                                             >
//                                                 {field.options.map((option) => (
//                                                     <MenuItem key={option._id} value={option._id}>
//                                                         {option?.title}
//                                                     </MenuItem>
//                                                 ))}
//                                             </Select>
//                                             {errors[field.name] && (
//                                                 <Typography
//                                                     variant="body2"
//                                                     color="error"
//                                                     sx={{
//                                                         margin: "0.75rem",
//                                                         marginTop: "0.4rem",
//                                                         fontSize: "0.75rem",
//                                                     }}
//                                                 >
//                                                     {errors[field.name]}
//                                                 </Typography>
//                                             )}
//                                         </div>
//                                     ) : field.type === "typography" ? (
//                                         <TextField
//                                             // label={field.label}
//                                             type={field.type}
//                                             id={field.name}
//                                             name={field.name}
//                                             value={getDropDownValue(formData, fieldname)}
//                                             onChange={(event) => handleChange(field.name)(event)}
//                                             fullWidth
//                                             error={!!errors[field.name]}
//                                             helperText={errors[field.name]}
//                                             disabled={field.disabled ? field.disabled : false}
//                                         />
//                                     ) : field.type === "description" ? (
//                                         <TextField
//                                             // label={field.label}
//                                             multiline
//                                             rows={7}
//                                             type={field.type}
//                                             id={field.name}
//                                             name={field.name}
//                                             value={getNestedProperty(formData, fieldname)}
//                                             onChange={(event) => handleChange(field.name)(event)}
//                                             fullWidth
//                                             error={!!errors[field.name]}
//                                             helperText={errors[field.name]}
//                                             disabled={field.disabled ? field.disabled : false}
//                                         />
//                                     ) : field.type === "file" ? (
//                                         <>
//                                             <input
//                                                 type="file"
//                                                 accept={accept}
//                                                 onChange={(event) =>
//                                                     handleChange(field.name, field.type)(event)
//                                                 }
//                                             />
//                                             {field.required && !formData[field.name] && (
//                                                 <p
//                                                     className={style.error_msg}
//                                                 >{`${field.label} is required`}</p>
//                                             )}
//                                         </>
//                                     ) : field.type === "date" ? (
//                                         <TextField
//                                             // label={field.label}
//                                             type={field.type}
//                                             id={field.name}
//                                             name={field.name}
//                                             value={formData[field.name]}
//                                             onChange={(event) => handleChange(field.name)(event)}
//                                             fullWidth
//                                             error={!!errors[field.name]}
//                                             helperText={errors[field.name]}
//                                             disabled={field.disabled ? field.disabled : false}
//                                             InputProps={{
//                                                 inputProps: { max: getCurrentDate() },
//                                             }}
//                                         />
//                                     ) : (
//                                         <>
//                                             <TextField
//                                                 // label={field.label}
//                                                 type={field.type}
//                                                 id={field.name}
//                                                 name={field.name}
//                                                 value={getNestedProperty(formData, fieldname)}
//                                                 onChange={(event) => handleChange(field.name)(event)}
//                                                 fullWidth
//                                                 error={!!errors[field.name]}
//                                                 helperText={errors[field.name]}
//                                                 disabled={field.disabled ? field.disabled : false}
//                                                 inputProps={
//                                                     field.name === "phone" ? { maxLength: 10 } : {}
//                                                 }
//                                             />
//                                         </>
//                                     )}
//                                 </div>
//                             );
//                         })}
//                     </div>
//                 </form>
//                 <Button onClick={handleCancel} color="secondary" variant="outlined">
//                     Cancel
//                 </Button>
//                 <Button
//                     onClick={handleSubmit}
//                     color="primary"
//                     variant="contained"
//                     sx={{ ml: 4 }}
//                 >
//                     Submit
//                 </Button>
//             </Box>
//         </Modal>
//     );
// };

// export default PatientModal;


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, MenuItem, Select } from "@mui/material";
import style from "./PatientModal.module.css";

import Indian_states_cities_list from "indian-states-cities-list";

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const PatientModal = ({
  accept,
  setSelectedCategory,
  isOpen,
  onClose,
  onSubmit,
  fields,
  resetFormData,
  header,
  initialData,
  isEditing,
}) => {
  const initialFormData = {};
  const initialErrors = {};
  fields.forEach((field) => {
    initialFormData[field.name] = field.isMultiSelect ? [] : "";
    initialErrors[field.name] = "";
  });
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  // console.log(Indian_states_cities_list.STATE_WISE_CITIES.Haryana)

  useEffect(() => {
    if (initialData) {
      const updatedFormData = { ...initialFormData };
      Object.keys(initialData).forEach((key) => {
        updatedFormData[key] = initialData[key];
      });
      fields.forEach((field) => {
        if (field.type === "date" && initialData[field.name]) {
          updatedFormData[field.name] = new Date(initialData[field.name])
            .toISOString()
            .split("T")[0];
        }
      });
      setFormData(updatedFormData);
    } else {
      setFormData(initialFormData);
    }
  }, [initialData]);

  const validateFormData = () => {
    const newErrors = {};

    fields.forEach((field) => {
      // if(field.name=='category' || 'subCategory') {
      //   return;
      // }
      // console.log(">>>>>>", formData[field.name], field.name)
      const isEmpty = !formData[field.name];
      const isEditingFileField = field.type === "file" && field.isEditing;
      const isFileEmpty = isEditingFileField && !formData[field.name].name;

      if (field.required && (isEmpty || isFileEmpty)) {
        newErrors[field.name] = `${field.label} is required`;
      } else {
        newErrors[field.name] = "";
      }
      // Trim the field value if it's a string before validation
      if (typeof formData[field.name] === 'string') {
        formData[field.name] = formData[field.name].trim();
      }
      if (
        field.name === "phone" &&
        formData[field.name] &&
        !/^\d{10}$/.test(formData[field.name])
      ) {
        newErrors[field.name] = "Phone number must be exactly 10 digits.";
      }
      if (
        field.name === "email" &&
        formData[field.name] &&
        !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData[field.name])
      ) {
        newErrors[field.name] = "Please enter a valid email address.";
      }
    });

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleChange = (fieldName, fieldType) => (event, newValue) => {
    if (Array.isArray(newValue)) {
      setFormData({ ...formData, [fieldName]: newValue });
    } else if (fieldType === "file") {
      const file = event.target.files[0];
      setFormData({ ...formData, [fieldName]: file });
    } else {
      setFormData({ ...formData, [fieldName]: event.target.value });
    }
  };

  const handleSubmit = () => {
    if (validateFormData()) {
      onSubmit(formData, isEditing, initialData._id);
      onClose();
    }
  };

  const handleCancel = () => {
    const clearedFormData = {};
    fields.forEach((field) => {
      clearedFormData[field.name] = field.isMultiSelect ? [] : "";
    });
    setFormData(clearedFormData);
    setErrors(initialErrors);
    onClose();
  };

  useEffect(() => {
    if (resetFormData) {
      setFormData(initialFormData);
      setErrors(initialErrors);
    }
  }, [resetFormData]);

  const getNestedProperty = (obj, path) => {
    const keys = path.split(".");
    let result = obj;

    for (const key of keys) {
      result = result[key];
      if (result === undefined) {
        return "";
      }
    }
    return result;
  };
  const getDropDownValue = (obj, path) => {
    const keys = path.split(".");
    let result = obj;

    for (const key of keys) {
      result = result[key];
      if (result === undefined) {
        return "";
      }
    }
    return result?._id;
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className={style.main_div}
      >
        <Typography
          id="modal-title"
          variant="h5"
          component="h2"
          align="center"
          style={{ textDecoration: "underline" }}
        >
          {header}
        </Typography>
        <form>
          <div style={{ margin: "1rem 0" }}>
            {fields.map((field) => {
              let fieldname = field.name;
              return (
                <div key={field.name} style={{ marginBottom: "10px" }}>
                  <Typography>{field.label}</Typography>
                  {field.isMultiSelect ? (
                    <Autocomplete
                      disabled={field.disabled ? true : false}
                      disablePortal
                      id={field.name}
                      options={field.options}
                      value={formData[field.name]}
                      // onChange={(_, newValue) =>
                      //   handleChange(field.name)(_, newValue)
                      // }
                      // getOptionLabel={(option) => option.label}
                      // getOptionValue={(option) => option.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]}
                        />
                      )}
                    />
                  ) : field.type === "option" ? (
                    <div>
                      <Select
                        label={field.label}
                        id={field.name}
                        name={field.name}
                        value={
                          getDropDownValue(formData, field.name) ??
                          formData[field.name]
                        }
                        onChange={(event) => handleChange(field.name)(event)}
                        fullWidth
                        error={!!errors[field.name]}
                        helperText={errors[field.name]}
                        disabled={field.disabled ? true : false}
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors[field.name] && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{
                            margin: "0.75rem",
                            marginTop: "0.4rem",
                            fontSize: "0.75rem",
                          }}
                        >
                          {errors[field.name]}
                        </Typography>
                      )}
                    </div>
                  ) : field.type === "category" ? (
                    <div>
                      <Select
                        label={field.label}
                        id={field.name}
                        name={field.name}
                        defaultValue={formData.category?._id}
                        value={
                          getDropDownValue(formData, field.name) ??
                          formData[field.name]
                        }
                        onChange={(event) => {
                          handleChange(field.name)(event);
                          setSelectedCategory(event.target.value);
                        }}
                        fullWidth
                        error={!!errors[field.name]}
                        helperText={errors[field.name]}
                        disabled={field.disabled ? true : false}
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors[field.name] && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{
                            margin: "0.75rem",
                            marginTop: "0.4rem",
                            fontSize: "0.75rem",
                          }}
                        >
                          {errors[field.name]}
                        </Typography>
                      )}
                    </div>
                  ) : field.type === "subCategory" ? (
                    <div>
                      <Select
                        label={field.label}
                        id={field.name}
                        name={field.name}
                        // defaultValues={getDropDownValue(formData, fieldname)}
                        value={
                          getDropDownValue(formData, field.name) ??
                          formData[field.name]
                        }
                        onChange={(event) => {
                          handleChange(field.name)(event);
                        }}
                        fullWidth
                        error={!!errors[field.name]}
                        helperText={errors[field.name]}
                        disabled={field.disabled ? true : false}
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option?.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors[field.name] && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{
                            margin: "0.75rem",
                            marginTop: "0.4rem",
                            fontSize: "0.75rem",
                          }}
                        >
                          {errors[field.name]}
                        </Typography>
                      )}
                    </div>
                  ) : field.type === "typography" ? (
                    <TextField
                      // label={field.label}
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={getDropDownValue(formData, fieldname)}
                      onChange={(event) => handleChange(field.name)(event)}
                      fullWidth
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                      disabled={field.disabled ? field.disabled : false}
                    />
                  ) : field.type === "description" ? (
                    <TextField
                      // label={field.label}
                      multiline
                      rows={7}
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={getNestedProperty(formData, fieldname)}
                      onChange={(event) => handleChange(field.name)(event)}
                      fullWidth
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                      disabled={field.disabled ? field.disabled : false}
                    />
                  ) : field.type === "file" ? (
                    <>
                      <input
                        type="file"
                        accept={accept}
                        onChange={(event) =>
                          handleChange(field.name, field.type)(event)
                        }
                      />
                      {field.required && !formData[field.name] && (
                        <p
                          className={style.error_msg}
                        >{`${field.label} is required`}</p>
                      )}
                    </>
                  ) : field.type === "date" ? (
                    <TextField
                      // label={field.label}
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={(event) => handleChange(field.name)(event)}
                      fullWidth
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                      disabled={field.disabled ? field.disabled : false}
                      InputProps={{
                        inputProps: { max: getCurrentDate() },
                      }}
                    />
                  ) : (
                    <>
                      <TextField
                        // label={field.label}
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={getNestedProperty(formData, fieldname)}
                        onChange={(event) => handleChange(field.name)(event)}
                        fullWidth
                        error={!!errors[field.name]}
                        helperText={errors[field.name]}
                        disabled={field.disabled ? field.disabled : false}
                        inputProps={
                          field.name === "phone" ? { maxLength: 10 } : {}
                        }
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </form>
        <Button onClick={handleCancel} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{ ml: 4 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default PatientModal;